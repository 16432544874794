
import { defineComponent, ref, unref, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';
import useFormErrors from '@/utils/validate';
import { Rules } from 'async-validator';

import { useCreateChannel, useUpdateChannel, useChannel } from '@/composables/api';
import { PartialChannel, ChannelType } from '@/interfaces/Channel';
import ChannelCommissions, { UpdateCommissionsEvent } from './ChannelCommissions.vue';
import { IntBoolean } from '@/services/api';
import { useI18n } from 'vue-i18n';

const DEFAULT_FORM_VALUES: PartialChannel = {
  utmSource: '',
  type: ChannelType.CPA,
  isBanned: IntBoolean.FALSE,
  channelCommissions: []
};

const rules: Rules = {
  utmSource: [
    {
      required: true
    }
  ],
  type: [
    {
      required: true
    }
  ]
};

const TYPE_OPTIONS = Object
  .entries(ChannelType)
  .map(([label, value]) => ({
    label, value
  }));

export default defineComponent({
  components: {
    ChannelCommissions
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const channelId = useRoute().params.id as string;
    const page = useRoute().query.page as string;
    const router = useRouter();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { t } = useI18n();

    const { isLoading: isCreatedLoading, mutate: create } = useCreateChannel();
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdateChannel();
    const { formErrors, bindFormItemError } = useFormErrors();
    const { data, dataUpdatedAt } = useChannel({ channelId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });

    const lastStageStartDate = ref();
    const lastStage = ref();

    watch(dataUpdatedAt, () => {
      formValues.value = data.value.data;
    });

    const handleUpdateCommissions = (event: UpdateCommissionsEvent) => {
      formValues.value.channelCommissions = event;
    };

    const getCommissionsValues = () => {
      formValues.value.channelCommissions = formValues.value.channelCommissions.filter((channelCommissions) => {
        return channelCommissions.id === undefined;
      });
    };

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      getCommissionsValues();

      form && form.validate((valid: boolean) => {
        if (valid) {
          if (isEdit.value) {
            update(
              { channelId, data: formValues.value },
              {
                onSuccess() {
                  ElMessage.success({
                    message: 'success!',
                    type: 'success'
                  });

                  formValues.value = DEFAULT_FORM_VALUES;
                  router.push({
                    name: 'list-channels'
                  });
                },
                onError(error: any) {
                  ElMessage.error({
                    message: error.response?.data.message,
                    type: 'error'
                  });
                  formErrors.value = error.response?.data.errors;
                }
              }
            );

            return;
          }

          create(
            { data: formValues.value },
            {
              onSuccess() {
                ElMessage.success({
                  message: 'success!',
                  type: 'success'
                });

                router.push({
                  name: 'list-channels'
                });
              },
              onError(error: any) {
                ElMessage.error({
                  message: error.response?.data.message,
                  type: 'error'
                });
                formErrors.value = error.response?.data.errors;
              }
            }
          );
        }
      });
    };

    return {
      page,
      rules,
      formRef,
      lastStage,
      formValues,
      formErrors,
      IntBoolean,
      TYPE_OPTIONS,
      isCreatedLoading,
      isUpdatedLoading,
      lastStageStartDate,
      submitForm,
      bindFormItemError,
      t,
      handleUpdateCommissions
    };
  }
});
